import "./iconsList.component.scss";
import { BsTelegram, BsSignal, BsWhatsapp } from "react-icons/bs";
import copy from 'copy-to-clipboard'
import { useState } from 'react'


const IconsList = () => {
  const [isCopied, setIsCopied] = useState(false)

  const onSignalClickCopy = (e) => { 
    e.preventDefault();  
    copy(`+${process.env.REACT_APP_CONTACT_NUMBER}`)
      setIsCopied(true)
  
      setTimeout(() => {
        setIsCopied(false)
      }, 300)
  }
  
  return (
    <ul className="icons-list">
      <li className="icons-item">
        <a href="https://t.me/adovgobrod">
          <BsTelegram className="icon telegram" />
        </a>
      </li>
      <li className="icons-item">
        <a href="#" className="fake-link" onClick={e => onSignalClickCopy(e)}>
          <BsSignal className="icon signal" />
          <span className={`copy-number ${isCopied ? 'active' : ''}`}>
          copied
        </span>
        </a>
      </li>
      <li className="icons-item">
        <a href={`https://wa.me/${process.env.REACT_APP_CONTACT_NUMBER}`}>
          <BsWhatsapp className="icon whatsapp" />
        </a>
      </li>
    </ul>
  );
};

export default IconsList;
