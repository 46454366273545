import ImageGallery from 'react-image-gallery';


const PhotoGalleryCarouselComponent = ({ items }) => {
    // const [modalIsOpen, setModalIsOpen] = useState(false)
    // const [photoIndex, setPhotoIndex] = useState(0)
  
    // const onPhotoClick = i => {
    //   setPhotoIndex(i)
    //   setModalIsOpen(true)
    // }
  
    return (
        <ImageGallery items={items} useBrowserFullscreen={false} showPlayButton={false}/>
    )
  }
  
  export default PhotoGalleryCarouselComponent