import parse from 'html-react-parser'

import translations from '../tranlations.json'

const BASE_LANG = 'UA'
const LANG_LIST = ['UA', 'EN']

const translate = id => {
  const lang = getInitialLang()
  return translations[id]
    ? parse(translations[id][lang])
    : '>>>> translation not found <<<<'
}

export const changeLang = value => {
  window.localStorage.setItem('lang', value)
  var url = new URL(window.location.href)
  url.searchParams.delete('lang')
  if (value !== BASE_LANG) {
    url.searchParams.append('lang', value)
  }
  window.history.pushState({}, null, url.href)
}

export const getInitialLang = () => {
  var url = new URL(window.location.href)
  let value = url.searchParams.get('lang') || BASE_LANG
  if (LANG_LIST.indexOf(value) === -1) {
    value = BASE_LANG
  }
  window.localStorage.setItem('lang', value)
  return value
}

export default translate
