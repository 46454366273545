import './link-scroll.component.scss'

const LinkScrollComponent = ({ to, children, className = '', ...props }) => {
  const onClick = e => {
    e.preventDefault()
    window.location.hash = to
    const target = window.document.querySelector(to)
    if (target) {
      target.scrollIntoView()
    }
  }

  return (
    <a
      className={`link-scroll-component ${className}`}
      {...props}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default LinkScrollComponent
