import './app.component.scss'

import HomePageComponent from '../home-page/home-page.component'

function AppComponent () {
  return (
    <div className='app-component'>
      <HomePageComponent />
    </div>
  )
}

export default AppComponent
